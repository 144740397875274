<template>
  <validation-provider
    :ref="`timepicker-${name}`"
    :vid="name"
    :name="label"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, validated }"
  >
    <b-form-group :label="label ? `${label} ${required ? '*' : ''}` : null">
      <input
        :name="name"
        :value="value"
        :class="{
          'form-control': true,
          'is-invalid': rules.length && validated && !valid,
          'is-valid': rules.length && validated && valid,
        }"
        type="time"
        autocomplete="off"
        :disabled="disabled"
        @input="$emit('input', $event.target.value);onChange($event)"
      />
      <p v-show="validated && !valid" class="error-container text-danger small">
        {{ errors[0] }}
      </p>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class TimePicker extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, default: '' })
  private name!: string;

  @Prop({ type: String })
  private value!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private required!: boolean;

  @Prop({ type: Array, default: () => [] })
  private validation!: string[];

  get rules() {
    return this.required ? this.validation.join('|').concat('|required') : this.validation.join('|');
  }

  @Watch('disabled')
  private changeDisabled() {
    (this.$refs[`timepicker-${this.name}`] as any).reset();
  }

  private onChange(event: any) {
    const { value } = event.target;
    this.$emit('change', { value, field: this.name });
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.error-container {
  position: absolute;
  top: 96%;
}

.form-control {
  &.is-valid {
    background-image: none;
  }

  &.is-invalid {
    background-image: none;
  }
}
</style>
