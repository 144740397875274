<template>
  <validation-provider
    :ref="`radio-input-${name}`"
    :vid="name"
    :name="label ? label : ' '"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors }"
  >
    <b-form-group
      v-if="verificaSeOptionsTemEstilo"
      :label="(showLabel && label) ? `${label} ${required ? '*' : ''}` : null">
      <b-form-radio
        v-for="option in optionsCast" :key="option.key"
        v-model="checked"
        :name="`${option.key}`"
        :style="option.style"
        :disabled="disabled"
        :disabledField="disabledField"
        :value="option.key"
        @change="handleChange"
      >
      {{ option.value }}
      </b-form-radio>
    </b-form-group>
    <b-form-group
      v-else
      :label="(showLabel && label) ? `${label} ${required ? '*' : ''}` : null">
      <b-form-radio-group
        v-model="checked"
        value-field="key"
        text-field="value"
        :name="name"
        :options="options"
        :state="state"
        :disabled="disabled"
        :disabledField="disabledField"
        @input="handleChange"
      />
     <p v-show="!state" class="error-container text-danger small">
        {{ errors[0] }}
     </p>
     <soi-button v-if="habilitaRemocao"
       label="Remover"
       icon="eraser"
       variant="link"
       class="text-info"
       v-b-popover.hover.right="{
         content:'Remover Marcador',
         boundary: 'viewport',
         placement: 'top'
       }"
       @click="$emit('removerRadio')"
    />
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import IStylizedDictionary from '@/models/Interfaces/IStylizedDictionary';
import IDictionary from '@/models/Interfaces/IDictionary';
import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class Radio extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: string;

  @Prop({ type: Boolean, default: true })
  public showLabel!: boolean;

  @Prop({ type: String, default: '' })
  public name!: string;

  @Prop({ default: '' })
  public value!: string | number | boolean;

  @Prop({ type: Array, default: () => [] })
  public options!: Array<IDictionary | IStylizedDictionary>;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: String, default: '' })
  public disabledField!: string;

  @Prop({ type: Array, default: () => [] })
  public validation!: string[];

  @Prop({ type: Boolean, default: false })
  public habilitaRemocao!: boolean;

  public checked: string | number | boolean;

  get state() {
    if (this.required) {
      return this.options.find((opt) => opt.key === this.checked) !== undefined;
    }

    return true;
  }

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }

  get retornaValue() {
    return this.value;
  }

  get optionsCast() {
    return this.options as IStylizedDictionary[];
  }

  get verificaSeOptionsTemEstilo() {
    return !!(this.options as IStylizedDictionary[]).some((o) => o.style);
  }

  constructor() {
    super();
    this.checked = this.value || '';
  }

  @Watch('value')
  public changeValue() {
    Vue.nextTick(() => {
      this.checked = this.value;
    });
  }

  @Watch('disabled')
  public onChangeDisabled() {
    (this.$refs[`radio-input-${this.name}`] as any).reset();
  }

  created() {
    this.checked = this.value;
  }

  public handleChange(value: number | string | boolean) {
    this.$emit('change', { field: this.name, value });
    this.$emit('input', value);
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.error-container {
  position: absolute;
  top: 90%;
}

.custom-control-label {
  color: red;
}
</style>
