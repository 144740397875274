import moment, { unitOfTime } from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export default class DateHelper {
  /**
 * Formata uma string de data no formato brasileiro (DD/MM/YYYY).
 * Lança um erro se a string fornecida não representar uma data válida.
 *
 * @param {string} str - A string contendo a data a ser formatada.
 * @returns {string} A data formatada no formato brasileiro.
 * @throws {Error} Se a string não representar uma data válida.
 */
  public static formatBR(str: string) {
    if (str) {
      const date = new Date(str);

      if (date instanceof Date) {
        return date.toLocaleDateString('pt-BR');
      }

      throw new Error('Data Inválida');
    }
    return 'Não Informado';
  }

  /**
 * Calcula a idade com base na data de nascimento fornecida.
 *
 * @param {string} str - A string contendo a data de nascimento no formato YYYY-MM-DD.
 * @returns {number} A idade calculada.
 */
  public static calculateAge(str: string) {
    const today = moment();
    const birthdate = moment(str);
    const diff = today.diff(birthdate, 'years');
    if (diff > 0) {
      return diff;
    }

    return 0;
  }

  /**
 * Calcula a diferença entre duas datas em uma unidade de medida específica.
 * Se nenhuma data for fornecida, utiliza a data atual.
 *
 * @param {unitOfTime.Diff} unidadeDeMedida - A unidade de medida para calcular a diferença (por exemplo, 'years', 'months', 'days', etc.).
 * @param {string} dataInicialInformada - A data inicial para calcular a diferença (opcional).
 * @param {string} dataFinalInformada - A data final para calcular a diferença (opcional).
 * @param {boolean} absoluto - Se true, retorna o valor absoluto da diferença.
 * @returns {number} A diferença entre as datas na unidade de medida especificada.
 */
  public static calcularDiferenca(unidadeDeMedida: unitOfTime.Diff,
    dataInicialInformada?: string, dataFinalInformada?: string, absoluto?: boolean) {
    const dataInicial = moment(dataInicialInformada) || moment();
    const dataFinal = moment(dataFinalInformada) || moment();

    const diferenca = dataInicial.diff(dataFinal, unidadeDeMedida);

    if (absoluto) {
      return diferenca || 0;
    }
    return Math.abs(diferenca) || 0;
  }

  /**
 * Retorna a data e hora atual formatada como 'DD-MM-YYYY-HH-mm'.
 *
 * @returns {string} A data e hora atual formatada.
 */
  public static datetimeNow() {
    return moment().format('DD-MM-YYYY-HH-mm');
  }

  /**
 * Converte uma string de data em um formato específico usado para pesquisa, adicionando 23 horas, 59 minutos e 59 segundos à data.
 *
 * @param {string} str - A string de data a ser convertida.
 * @returns {string} A data convertida no formato para pesquisa.
 */
  public static convertSearchDate(str: string) {
    const data = moment(str).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
    return moment(data).format();
  }

  /**
 * Calcula a duração entre duas datas e horas.
 *
 * @param {Date} dataHoraInicio - A data e hora de início.
 * @param {Date} dataHoraFim - A data e hora de término.
 * @returns {string} A duração formatada como 'HH:mm:ss'.
 */
  public static calculaDuracao(dataHoraInicio: Date, dataHoraFim: Date) {
    const ms = moment(dataHoraFim, 'DD/MM/YYYY HH:mm:ss').diff(moment(dataHoraInicio, 'DD/MM/YYYY HH:mm:ss'));
    const d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  }

  /**
 * Retorna a quantidade de dias até a data de vencimento informado.
 *
 * @param {Date} dataVencimento - Data de vencimento.
 * @returns {number} quantidade de dias até vencer.
 */
  public static calcularDiasAteVencimento(dataVencimento: Date): number {
    const hoje = moment();
    const DataAteVencer = moment(dataVencimento);
    const diasRestantes = DataAteVencer.diff(hoje, 'days');

    return diasRestantes;
  }

  /**
 * Obtém o mês e o ano atual no formato de string.
 *
 * @returns {string} Uma string representando o mês e o ano atuais no formato "mês de ano".
 */
  public static formatarMesEAnoAtual(): string {
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro',
    ];

    const dataAtual = new Date();
    const mesAtual = meses[dataAtual.getMonth()];
    const anoAtual = dataAtual.getFullYear();

    return `${mesAtual} de ${anoAtual}`;
  }

  /**
 * Formata o dia, mês e ano atual em uma string.
 *
 * @returns {string} Uma string representando o dia, mês e ano atuais no formato "dia de mês de ano".
 */
  public static formatarDiaMesAnoAtual(): string {
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro',
    ];

    const dataAtual = new Date();
    const diaAtual = dataAtual.getDate();
    const mesAtual = meses[dataAtual.getMonth()];
    const anoAtual = dataAtual.getFullYear();

    return `${diaAtual} de ${mesAtual} de ${anoAtual}`;
  }
}
