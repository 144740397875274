<template>
  <validation-provider
    :ref="ref"
    :vid="name"
    :name="name"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, invalid, validated }"
  >
    <b-form-group :label="`${label}${required ? ' *' : ''}`">
      <b-row>
        <b-col>
          <b-form-file
            :accept="accept || ''"
            :placeholder="placeholder || 'Selecione um arquivo.'"
            :class="{
              'valid': rules.length && validated ? valid : null,
              'is-invalid': rules.length && validated ? invalid : null,
            }"
            v-model="vmodel"
            @input="$emit('input', { field: name, value: $event })"
          />
        </b-col>
        <b-col md="1" v-if="removivel">
          <soi-icon-button
            icon="trash"
            variant="danger"
            :disabled="disableRemove"
            @click="$emit('delete')"
          />
        </b-col>
      </b-row>
      <b-form-invalid-feedback>
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import ComponenteBase from '../ComponenteBase.vue';

@Component({
  props: {
    label: String,
    name: String,
    disabled: Boolean,
    placeholder: String,
    validation: Object,
    accept: String,
    value: File,
    removivel: Boolean,
    overrideDeleteDisabled: Boolean,
  },
})
export default class FormFile extends ComponenteBase {
  public label!: String;

  public name!: String;

  public disabled?: Boolean;

  public placeholder?: String;

  public validation!: string[];

  public accept?: String;

  public value!: File;

  public vmodel: File;

  public removivel?: Boolean;

  public overrideDeleteDisabled?: Boolean;

  constructor() {
    super();
    if (this.value) {
      this.vmodel = this.value;
    } else {
      this.vmodel = null!;
    }
  }

  @Watch('value')
  private onChange() {
    Vue.nextTick(() => {
      if (this.value) {
        this.vmodel = this.value;
      } else {
        this.vmodel = null!;
      }
    });
  }

  get rules() {
    return this.validation || [];
  }

  get required() {
    return this.validation?.find((rule) => rule === 'required');
  }

  get ref() {
    return `form-file-${this.name}`;
  }

  get disableRemove() {
    return !this.vmodel && !this.overrideDeleteDisabled;
  }
}
</script>

<style lang="scss" scoped>
.b-form-file.is-invalid {
  &.b-form-file--active {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.b-form-file.valid {
  &.b-form-file--active {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
}
</style>
