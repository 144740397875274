import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '..';
import {
  CHANGE_VALUES,
} from '../actions/RedirecionamentoActions';
import RedirecionamentoFiltro, { IRedirecionamentoFiltro } from '../../models/PagedTable/RedirecionamentoFiltro';


export interface IRedirecionamentoFiltroState {
  redirecionamentoFiltro: IRedirecionamentoFiltro;
}

@Module({
  name: 'redirecionamentoFiltro',
  namespaced: true,
  dynamic: true,
  store,
})
class RedirecionamentoFiltroModule extends VuexModule implements IRedirecionamentoFiltroState {
  private _redirecionamentoFiltro: IRedirecionamentoFiltro = new RedirecionamentoFiltro();


  get redirecionamentoFiltro(): IRedirecionamentoFiltro {
    return this._redirecionamentoFiltro;
  }

  @Mutation
  public SET_REDIRECTION_FILTRO_M(redirection: IRedirecionamentoFiltro) {
    this._redirecionamentoFiltro = redirection;
  }

  @Mutation
  private SET_REDIRECTION_M(redirection: IRedirecionamentoFiltro) {
    this._redirecionamentoFiltro = redirection;
  }

  @Action
  public async [CHANGE_VALUES]({ field, value }) {
    const redirection = this.redirecionamentoFiltro;
    (redirection as any)[field] = value;
    this.SET_REDIRECTION_M(redirection);
  }
}

export default getModule(RedirecionamentoFiltroModule);
