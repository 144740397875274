<template>
  <validation-provider
    :ref="`select-${name}`"
    :vid="name"
    :name="label"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, invalid, validated }"
  >
    <b-form-group :label="label ? `${label} ${required ? '*' : ''}` : null"  size="100">
      <multiselect
        label="value"
        track-by="value"
        v-model="selectedItem"
        :name="name"
        :max="maximoSelecao"
        :multiple="selecaoMultipla"
        :placeholder="placeholder"
        :options="options"
        :class="{
          'valid': (rules.length && validated) ? valid : null,
          'is-invalid': (rules.length && validated) ? invalid : null
        }"
        :disabled="disabled"
        :show-labels="false"
        :searchable="false"
        :close-on-select="true"
        :allow-empty="allowEmpty"
        @select="$emit('change', { value: $event.key, field: name })"
        @input="$emit('input', $event)"
        @remove="$emit('change', { value: '', field: name })"
      >
        <template v-slot:singleLabel="{ option }">
          <input type="text"
            :value="option.value"
            :disabled="disabled"
            v-b-popover.hover.top="option.label ? option.label : ''"
           >
        </template>
        <template slot="noOptions">
          Nenhuma opção disponível
        </template>
        <template slot="noResult">
          Nenhum resultado encontrado
        </template>
      </multiselect>
      <b-form-invalid-feedback :id="name">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import IDictionary from '../../models/Interfaces/IDictionary';
import ComponenteBase from '../ComponenteBase.vue';

@Component({ components: { Multiselect } })
export default class FormSelect extends ComponenteBase {
  @Prop({ default: '' })
  public label!: string;

  @Prop({ default: '' })
  public name!: string;

  @Prop({ default: '' })
  private selected!: number | string;

  @Prop({ type: Array, default: () => [] })
  public options!: IDictionary[];

  @Prop({ default: 'Selecione uma Opção' })
  public placeholder!: string;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ type: Array, default: () => [] })
  private validation!: string[];

  @Prop({ type: Boolean, default: true })
  public allowEmpty!: boolean;

  @Prop({ type: Number, default: 100 })
  public maximoSelecao!: number;

  @Prop({ type: Boolean, default: false })
  public selecaoMultipla!: boolean;

  public selectedItem: any;

  constructor() {
    super();
    const item = this.options.find((option) => String(option.key) === String(this.selected));
    if (item) {
      this.selectedItem = item;
    } else {
      this.selectedItem = '';
    }
  }

  @Watch('selected')
  private changeSelectedValue() {
    Vue.nextTick(() => {
      const item = this.options.find((option) => String(option.key) === String(this.selected));
      if (item) {
        this.selectedItem = item;
      } else {
        this.selectedItem = '';
      }
    });
  }

  @Watch('disabled')
  private changeDisabled() {
    (this.$refs[`select-${this.name}`] as any).reset();
  }

  @Watch('options')
  private onChangeOptions() {
    const item = this.options.find((option) => String(option.key) === String(this.selected));
    if (item) {
      this.selectedItem = item;
    } else {
      this.selectedItem = '';
    }
  }

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }
}
</script>

<style lang="scss">
.multiselect {
  min-height: auto;

  .multiselect__option--selected {
    background-color: #fff;
    font-weight: normal;
  }

  .multiselect__option--highlight {
    background: linear-gradient(15deg, #66bb6a, #43a047);
  }

  .multiselect__tags {
    font-size: 1rem;
    height: calc(1.5em + 0.75em + 2px);
    min-height: calc(1.5em + 0.75em + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    input {
      display: block;
      width: 100%;
      border: none;
      outline: none;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      border-color: #80bdff;

    }

    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    border-radius: 0.25rem;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

  }
}

.multiselect.is-invalid {
  .multiselect__select::before {
    color: #dc3545;
    border-color: #dc3545 transparent transparent;
  }

  .multiselect__tags {
    border-color: #dc3545;
  }

  &.multiselect--active {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.multiselect.valid {
  .multiselect__select::before {
    color: #28a745;
    border-color: #28a745 transparent transparent;
  }

  .multiselect__tags {
    border-color: #28a745;
  }

  &.multiselect--active {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
}
</style>
