<template lang="html">
  <soi-radio
        name="tipoImpressao"
        label="Tipo de Impressão:"
        :value="selected"
        :options="tipoImpressao"
        @change="setarModoImpressora"
    />
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import ComponenteBase from './ComponenteBase.vue';
import TipoImpressao from '../constants/TipoImpressao';
import IInputChangePayload from '../models/Interfaces/IInputChangePayload';

@Component
export default class Panel extends ComponenteBase {
  private selected: string;

  constructor() {
    super();
    this.selected = '#cccccc';
    this.setarBgImpressos(this.selected);
  }

  private setarBgImpressos(valor: string) {
    const html = (document as Document).querySelector('html');
    (html as HTMLHtmlElement).style.setProperty('--bg-impressos', valor);
  }

  private setarModoImpressora(payload: IInputChangePayload) {
    this.selected = payload.value.toString();
    this.setarBgImpressos(this.selected);
  }

  get tipoImpressao() {
    return TipoImpressao;
  }
}

</script>
