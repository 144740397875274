export interface IPagedRequest {
  page: number;
  pageSize?: number;
  termoDigitado?: any;
  orderDesc?: boolean;
  orderBy?: string;
  termoDigitados?: Array<any>;
}

export default class PagedRequest implements IPagedRequest {
  public page: number;

  public pageSize?: number;

  public termoDigitado?: any;

  public tenantId?: number;

  public orderDesc?: boolean;

  public orderBy?: string;

  public termoDigitados?: Array<any>;

  constructor(page = 1, pageSize = 5) {
    this.page = page;
    this.pageSize = pageSize;
    this.termoDigitados = [];
  }
}
