<template>
  <validation-provider
    :ref="`number-input-${name}`"
    :vid="name"
    :name="label ? label : ' '"
    :rules="rules"
    :disabled="disabled"
    v-slot="{ errors, valid, validated }"
  >
    <b-form-group :label="label ? `${label} ${required ? '*' : ''}` : null" class="form-group">
      <div>
        <b-form-input
          v-bind:class="{ mostraIcone: isCurrency || porcentagem}"
          :name="name"
          type="number"
          v-model="number"
          :min="min"
          :max="max"
          :placeholder="placeholder"
          :disabled="disabled"
          :step="precisao"
          :state="(rules.length && validated) ? valid : null"
          :isCurrency="false"
          :porcentagem="false"
          @input="$emit('change', { value: $event, field: name })"
          @blur="$emit('blur', $event)"
        />
        <i v-if="isCurrency" class="icon-currency fa fa-brazilian-real-sign"></i>
        <i v-if="porcentagem" class="icon-currency fas fa-percentage"></i>
      </div>
      <b-form-invalid-feedback class="error-container">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import Precisao from '../../constants/Precisao';
import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class NumberInput extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, default: '' })
  private name!: string;

  @Prop({ type: [Number, String], default: '' })
  private value!: number | string;

  @Prop({ type: String, default: '' })
  private placeholder!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Array, default: () => [] })
  private validation!: string[];

  @Prop({ type: String, default: '' })
  private min!: string;

  @Prop({ type: String, default: '' })
  private max!: string;

  @Prop({ type: Number, default: 0 })
  private casasDecimais!: number;

  @Prop({ type: Boolean, default: false })
  private isCurrency!: boolean;

  @Prop({ type: Boolean, default: false })
  private porcentagem!: boolean;

  private number: number | string;

  constructor() {
    super();
    this.number = this.value;
  }

  @Watch('value')
  private changeValue() {
    this.number = this.value;
  }

  @Watch('disabled')
  private onChangeDisabled() {
    (this.$refs[`number-input-${this.name}`] as any).reset();
  }

  get rules() {
    return this.validation.join('|');
  }

  get required() {
    return this.validation.find((rule) => rule === 'required');
  }

  get precisao() {
    return Precisao.find((item) => item.key === this.casasDecimais)?.value;
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.error-container {
  position: absolute;
  top: 90%;
}

.mostraIcone {
  left: 10%;
  padding-left: 30px;
}

.icon-currency {
  position:absolute;
  top:30%;
  left: 2%;
  color: silver;
  right:1;
  background:transparent;
  text-align: 30%;
}
</style>
