import Cookies from 'js-cookie';
import JwtHelper from '../helpers/JwtHelper';
import { parsePermissions } from './permission';
import AuthUser, { IAuthUserModel } from '../models/Auth/AuthUserModel';

export function getToken(): string {
  return Cookies.get('@metra:token') || '';
}

export function setToken(token: string) {
  Cookies.set('@metra:token', token);
}

export function removeToken() {
  Cookies.remove('@metra:token');
}

export function decode(token: string): IAuthUserModel {
  if (token.length) {
    const decoded = JwtHelper.decodeToken(token);

    const permissions: Array<string> = parsePermissions(decoded.permissions);

    const data: IAuthUserModel = {
      id: decoded.userid,
      name: decoded.name,
      userName: decoded.username,
      profilePicture: decoded.profilePicture,
      role: decoded.role,
      permissions,
      empresaId: decoded.empresaid,
      tenantId: decoded.tenantid,
      razaoSocial: decoded.razaoSocial,
      versaoTeste: decoded.versaoTeste,
      dataInadimplencia: decoded.dataInadimplencia,
      carencia: decoded.carencia,
    };
    return data;
  }

  return new AuthUser();
}
