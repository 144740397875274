export const ROUTER_VIEW = () => import('../views/RouterView.vue');

export const LOGIN = () => import('../views/Login.vue');

export const RECOVERY_PASSWORD_FORM = () => import('../views/Email/RecoveryPassword.vue');

export const CLINIC = () => import('../views/Clinic/Clinics.vue');
export const CLINIC_FORM = () => import('../views/Clinic/ClinicsForm.vue');

export const COMPANY = () => import('../views/Company/Companies.vue');
export const COMPANY_FORM = () => import('../views/Company/CompanyForm.vue');

export const USER = () => import('../views/Users/Users.vue');
export const USER_FORM = () => import('../views/Users/UsersForm.vue');
export const MY_USER = () => import('../views/Users/MyUser.vue');

export const EMPLOYEE = () => import('../views/Employee/Employees.vue');
export const EMPLOYEE_FORM = () => import('../views/Employee/EmployeeForm.vue');

export const EQUIPAMENT = () => import('../views/Equipament/Equipaments.vue');
export const EQUIPAMENT_FORM = () => import('../views/Equipament/EquipamentForm.vue');

export const DEPARTMENT = () => import('../views/Department/Departments.vue');
export const DEPARTMENT_FORM = () => import('../views/Department/DepartmentForm.vue');

export const AMBIENCE_FORM = () => import('../views/Ambience/Ambience.vue');

export const FUNCTION = () => import('../views/Functions/Functions.vue');
export const FUNCTION_FORM = () => import('../views/Functions/FunctionsForm.vue');

export const PROFESSIONAL_RECORD = () => import('../views/ProfessionalRecord/ProfessionalRecords.vue');
export const PROFESSIONAL_RECORD_FORM = () => import('../views/ProfessionalRecord/ProfessionalRecordForm.vue');

export const CAT = () => import('../views/Cat/Cats.vue');
export const CAT_FORM = () => import('../views/Cat/CatForm.vue');

export const SERVICE_PROVIDER = () => import('../views/ServiceProvider/ServicesProviders.vue');
export const SERVICE_PROVIDER_FORM = () => import('../views/ServiceProvider/ServiceProviderForm.vue');
export const PCMSO = () => import('../views/Pcmso/Pcmso.vue');
export const PCMSO_FORM = () => import('../views/Pcmso/PcmsoForm.vue');

export const AUDITORIA = () => import('../views/Audit/Audits.vue');
export const PANEL = () => import('../views/Panel/Panel.vue');

export const TREINAMENTOS = () => import('../views/Training/Treinamentos.vue');
export const TREINAMENTOS_FORM = () => import('../views/Training/TreinamentosForm.vue');

export const ASO = () => import('../views/Aso/Asos.vue');
export const ASO_FORM = () => import('../views/Aso/AsoForm.vue');

export const ESOCIAL_EVENT = () => import('../views/ESocial/ESocialEvent.vue');
export const ESOCIAL_MANAGEMENT = () => import('../views/ESocial/ESocialManagement.vue');

export const MYCOMPANY_FORM = () => import('../views/Company/MyCompanyForm.vue');

export const CONFIRM_EMAIL = () => import('../views/Email/ConfirmEmail.vue');

export const AGENDA = () => import('../views/Agenda/AgendaView.vue');

export const ESOCIAL_DASHBOARD = () => import('../views/ESocial/ESocialDashboard.vue');

export const RELATORIO_FUNCIONARIO_LOTE = () => import('../views/ESocial/RelatorioDeFuncionarioPorLote.vue');

export const ESOCIAL_DUVIDAS = () => import('../views/DuvidasEsocial/ESocialDuvidas.vue');

export const QUESTAO_106 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-106.vue');

export const QUESTAO_17 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-17.vue');

export const QUESTAO_272 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-272.vue');

export const QUESTAO_130 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-130.vue');

export const QUESTAO_174 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-174.vue');

export const QUESTAO_180_303_304 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-180-303-304.vue');

export const QUESTAO_402 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-402.vue');

export const QUESTAO_411 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-411.vue');

export const QUESTAO_459 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-459.vue');

export const QUESTAO_543 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-543.vue');

export const QUESTAO_607 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-607.vue');

export const QUESTAO_609 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-609-632-1089.vue');

export const QUESTAO_1323 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-1323.vue');

export const QUESTAO_ATUALIZACAO = () => import('../views/DuvidasEsocial/Questoes_Diversas/Questao-Atualizacao.vue');

export const QUESTAO_CONEXAO_CNPJ = () => import('../views/DuvidasEsocial/Questoes_Diversas/Questao-conexao-cnpj.vue');

export const QUESTAO_EVENTO_NAO_ENCONTRADO = () => import('../views/DuvidasEsocial/Questoes_Diversas/Questao-evento-nao-encontrado.vue');

export const QUESTAO_AO_CRIAR_LOTE = () => import('../views/DuvidasEsocial/Questoes_Diversas/Questao-erro-ao-criar-lote.vue');

export const LOTE_DASHBOARD = () => import('../views/ESocial/LoteDashboard.vue');

export const ESOCIAL_PANEL = () => import('../views/ESocial/ESocialPanel.vue');

export const PGR = () => import('../views/PGR/PGRs.vue');
export const PGR_FORM = () => import('../views/PGR/PGRForm.vue');
export const PLANEJAMENTO_ANUAL = () => import('../views/PGR/components/CardPlanejamentoAnual.vue');

export const LTCAT = () => import('../views/LTCAT/Ltcats.vue');
export const LTCAT_FORM = () => import('../views/LTCAT/LtcatForm.vue');

export const LTIP = () => import('../views/LTIP/Ltips.vue');
export const LTIP_FORM = () => import('../views/LTIP/LtipForm.vue');

export const LAUDO_PERICULOSIDADE = () => import('../views/LaudoPericulosidade/LaudosPericulosidades.vue');
export const LAUDO_PERICULOSIDADE_FORM = () => import('../views/LaudoPericulosidade/LaudoPericulosidadeForm.vue');

export const LAUDO_INSALUBRIDADE = () => import('../views/LaudoInsalubridade/LaudosInsalubridade.vue');
export const LAUDO_INSALUBRIDADE_FORM = () => import('../views/LaudoInsalubridade/LaudoInsalubridadeForm.vue');

export const QUESTAO_1557 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-1557.vue');

export const QUESTAO_381 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-381.vue');

export const QUESTAO_1170 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-1170.vue');

export const QUESTAO_1172 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-1172.vue');

export const QUESTAO_1748 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-1748.vue');

export const QUESTAO_1327 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-1327.vue');

export const QUESTAO_203 = () => import('../views/DuvidasEsocial/Questoes_Esocial/Questao-203.vue');

export const QUESTAO_CONECTOR = () => import('../views/DuvidasEsocial/Questoes_Diversas/Questao-conector.vue');

export const IMPRESSO_FORM = () => import('../views/Impresso/ImpressoForm.vue');

export const ACEITE_CLINICAGESTAO = () => import('../views/Aceite/AceiteClinicaGestaoForm.vue');

export const ACEITE_EMPREGADORGESTAO = () => import('../views/Aceite/AceiteEmpregadorGestao.vue');

export const FATURA = () => import('../views/Faturamento/Fatura/Faturas.vue');

export const CONVENIO = () => import('../views/Faturamento/Convenio/Convenios.vue');

export const SERVICO_FATURAVEL = () => import('../views/Faturamento/Servico/Servicos.vue');

export const PROCEDIMENTOS = () => import('../views/Faturamento/Procedimento/Procedimentos.vue');

export const CONFIGURARELATORIOFUNCIONARIOSPOREMPRESA = () => import('../views/Relatorio/ConfiguraRelatorioFuncionariosPorEmpresa.vue');

export const CONFIGURARELATORIOASO = () => import('../views/Relatorio/ConfiguraRelatorioAso.vue');

export const CONFIGURARELATORIOFATURAMENTO = () => import('../views/Relatorio/ConfiguraRelatorioFaturamento.vue');

export const CONFIGURARELATORIOCONVOCACAOPERIODICA = () => import('../views/Relatorio/ConfiguraRelatorioConvocacaoPeriodica.vue');

export const CERTIFICADO_DIGITAL_FORM = () => import('../views/Users/ConfiguracaoAssinaturaDigital.vue');

export const CABECALHO_RODAPE = () => import('../views/Users/ConfiguracaoCabecalhoRodape.vue');

export const CONFIGURA_RELATORIO_DE_EMPRESA = () => import('../views/Relatorio/ConfigurarRelatorioEmpresa.vue');

export const IMPORTA_FUNCIONARIO = () => import('../views/Importacao/ImportacaoPlanilha.vue');

export const MOVIMENTACAO_EPI = () => import('../views/MovimentacaoDeEPI/MovimentacaoDeEPI.vue');

export const CBO_FORM = () => import('../views/CBO/CBOForm.vue');

export const DASHBOARD_VIEW = () => import('../views/Dashboard/Dashboard.vue');

export const AFASTAMENTOS = () => import('../views/Afastamento/Afastamentos.vue');

export const AFASTAMENTO_FORM = () => import('../views/Afastamento/AfastamentoForm.vue');

export const EXAMES_TOXICOLOGICOS = () => import('../views/ExameToxicologico/ExamesToxicologicos.vue');

export const RISCO_AGENTE_NOCIVO_FORM = () => import('../views/RiscoEAgenteNocivo/RiscoEAgenteNocivoForm.vue');

export const ORDENS_DE_SERVICO = () => import('../views/OrdemDeServico/OrdensDeServicos.vue');

export const ORDEM_DE_SERVICO_FORM = () => import('../views/OrdemDeServico/OrdemDeServicoForm.vue');

export const IMPORTACAO_EVENT = () => import('../views/Importacao/ImportacaoEvent.vue');

export const CONFIGURA_RELATORIO_MOVIMENTACAO_EPI = () => import('../views/Relatorio/ConfiguraRelatorioMovimentacaoEPI.vue');

export const APARELHOS = () => import('../views/Aparelho/Aparelhos.vue');

export const FICHA_CLINICA_FORM = () => import('../views/FichaClinica/FichaClinicaForm.vue');

export const CONFIGURACOES_GERAIS = () => import('../views/Configuracoes/Configuracoes.vue');

export const EXAME_TOXICOLOGICO_FORM = () => import('../views/ExameToxicologico/ExameToxicologicoForm.vue');
